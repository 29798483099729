<template>
  <div>
    <v-container>
      <v-row no-gutters>
        <v-col cols="12">
          <h4>Регистър брак</h4>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="headers.search"
            prepend-inner-icon="search"
            clearable
            :label="$t('A.SEARCH')"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-select
            v-model="headers.client"
            :items="clients"
            item-text="name"
            item-value="id"
            label="Клиент"
            attach
          ></v-select>
        </v-col>
        <v-col cols="4" class="text-center"> </v-col>
        <v-col cols="4">
          <div class="form-group">
            <label for="">
              <span> Начална дата:</span>
            </label>
            <div class="field-wrap">
              <datetime
                zone="local"
                value-zone="local"
                type="date"
                v-model="headers.start_date"
                input-class="form-control"
              ></datetime>
            </div>
          </div>
        </v-col>
        <v-col cols="4">
          <div class="form-group">
            <label for="">
              <span> Крайна дата:</span>
            </label>
            <div class="field-wrap">
              <datetime
                zone="local"
                value-zone="local"
                type="date"
                v-model="headers.end_date"
                input-class="form-control"
              ></datetime>
            </div>
          </div>
        </v-col>
        <v-col cols="4" class="text-center">
          <div v-on:click="downloadData()" class="btn btn-primary">
            Свали експорт
          </div>
        </v-col>
      </v-row>

      <div>
        <v-row class="mb-6 t-header">
          <v-col>
            Име
          </v-col>
          <v-col>
            Партида
          </v-col>
          <v-col>
            Брой
          </v-col>
          <v-col>
            Дата
          </v-col>
        </v-row>

        <div v-show="!isLoadingStats">
          <v-row class="mb-6 t-row" v-for="item of items" :key="item.id">
            <template v-if="item.productName != null">
              <v-col>
                {{ item.productName }}
              </v-col>
              <v-col>
                {{ item.batch }}
              </v-col>
              <v-col>
                {{ item.quantity }}
              </v-col>
              <v-col>
                {{ item.date }}
              </v-col>
            </template>
          </v-row>
        </div>
        <br />
        <br />
        <br />
      </div>
      <v-progress-linear
        class="mt-5"
        color="#25bdad"
        buffer-value="0"
        stream
        reverse
        :active="isLoadingStats"
      ></v-progress-linear>
    </v-container>
  </div>
</template>

<script>
import _ from "lodash";

import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import { GET_ALL_CLIENTS } from "@/store/clients.module";

import {
  GET_ALL_SCRAP_REGISTER,
  DOWNLOAD_SCRAP_REGISTER
} from "@/store/scrapRegister.module";

import { mapGetters } from "vuex";
import { API_URL } from "@/common/config";

export default {
  name: "ScrapRegisterList",

  components: {},

  data() {
    return {
      search: "",
      clients: [],
      action: "scrapRegister",
      tableOptions: {
        showSearch: false,
        draggableCols: true,
        selectable: true,
        rowsPerPage: [2, 10, 20, 50],
        maxVisibleButtons: 5
      },
      headers: {
        columns: [
          {
            name: "id",
            cols: "1"
          },
          {
            name: "batch",
            search: ""
          },
          {
            name: "signed_by_qualified",
            search: "",
            hide: true
          },
          {
            name: "status_id",
            search: "",
            hide: true
          },
          {
            name: "material_id",
            search: "",
            hide: true
          },
          {
            name: "material.type.name",
            search: ""
          },
          {
            name: "material.name",
            search: ""
          },
          {
            name: "date"
          },
          {
            name: "status.name"
          }
        ],
        start_date: null,
        end_date: null,
        client: "",
        search: "",
        rows: 10,
        page: 1,
        order: "id",
        orderType: "asc"
      },
      items: [],
      totalItems: 0,
      totalPages: 0
    };
  },
  computed: {
    ...mapGetters(["isLoadingStats"]),
    orderIcon: function() {
      let vm = this;

      return vm.headers.orderType == "asc" ? "▲" : "▼";
    },
    visiblePages() {
      const range = [];
      let vm = this;

      let start, end;

      if (vm.headers.page < Math.ceil(vm.tableOptions.maxVisibleButtons / 2)) {
        start = 1;

        if (vm.tableOptions.maxVisibleButtons > vm.totalPages) {
          end = vm.totalPages;
        } else {
          end = vm.tableOptions.maxVisibleButtons;
        }
      } else if (
        vm.headers.page + Math.ceil(vm.tableOptions.maxVisibleButtons / 2) >
        vm.totalPages
      ) {
        start = vm.totalPages - vm.tableOptions.maxVisibleButtons + 1;
        end = vm.totalPages;
      } else {
        start =
          vm.headers.page - Math.floor(vm.tableOptions.maxVisibleButtons / 2);
        end =
          vm.headers.page + Math.floor(vm.tableOptions.maxVisibleButtons / 2);
      }

      for (let i = start; i <= end; i += 1) {
        range.push({
          name: i,
          isDisabled: i === vm.headers.page
        });
      }

      return range;
    }
  },
  watch: {
    "headers.client": _.debounce(function() {
      let vm = this;

      vm.headers.page = 1;

      vm.fetchData();
    }, 250),
    "headers.start_date": _.debounce(function() {
      let vm = this;

      vm.headers.page = 1;

      vm.fetchData();
    }, 250),
    "headers.end_date": _.debounce(function() {
      let vm = this;

      vm.headers.page = 1;

      vm.fetchData();
    }, 250),
    "headers.search": _.debounce(function() {
      let vm = this;

      vm.fetchData();
    }, 250),
    "headers.rows": _.debounce(function(new_value) {
      let vm = this;
      new_value = parseInt(new_value);
      window.localStorage.setItem(vm.action + "_rows", new_value);

      vm.headers.page = 1;
      vm.fetchData();
    }, 250),
    "headers.page": _.debounce(function() {
      let vm = this;
      vm.fetchData();
    }, 250)
  },
  mounted() {
    let vm = this;
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Стока в наличност" }]);

    vm.getClients();
    vm.fetchData();
  },
  methods: {
    getClients: function() {
      let vm = this;

      this.error_messages = [];
      vm.$store
        .dispatch(GET_ALL_CLIENTS)
        .then(data => {
          vm.$nextTick(function() {
            vm.clients = data.data;
          });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });
        });
    },
    getOptions: function() {
      let vm = this;

      vm.headers.order =
        window.localStorage.getItem(vm.action + "_order") || "id";
      vm.headers.orderType =
        window.localStorage.getItem(vm.action + "_orderType") || "asc";
      vm.headers.rows = parseInt(
        window.localStorage.getItem(vm.action + "_rows") || 10
      );
    },
    changeOrder: _.debounce(function(key) {
      let vm = this;

      if (vm.headers.order == key) {
        let oType = vm.headers.orderType == "asc" ? "desc" : "asc";

        vm.headers.orderType = oType;
      } else {
        vm.headers.order = key;
      }

      vm.headers.page = 1;
      window.localStorage.setItem(vm.action + "_order", vm.headers.order);
      window.localStorage.setItem(
        vm.action + "_orderType",
        vm.headers.orderType
      );

      vm.fetchData();
    }, 250),
    getSearchPayload: function() {
      return {
        search: this.headers.search,
        client: this.headers.client,
        start_date: this.headers.start_date,
        end_date: this.headers.end_date
      };
    },
    fetchData: _.debounce(function() {
      let vm = this;

      vm.$store
        .dispatch(GET_ALL_SCRAP_REGISTER, vm.getSearchPayload())
        .then(data => {
          vm.$nextTick(function() {
            vm.items = data.data;
          });
        })
        .catch(response => {
          if (response.status === 404) {
            vm.$notification["success"]({
              message: response.status,
              description: response.message
            });
            vm.$router.push({ name: "dashboard" });
          }
        });
    }, 500),
    downloadData: _.debounce(function() {
      let vm = this;

      vm.$store
        .dispatch(DOWNLOAD_SCRAP_REGISTER)
        .then(data => {
          let payload = vm.getSearchPayload();

          window.open(
            API_URL +
              `scrap/export/${data.data.code}?search=${payload.search}&client=${payload.client}&start_date=${payload.start_date}&end_date=${payload.end_date}`
          );
        })
        .catch(response => {
          if (response.status === 404) {
            vm.$notification["success"]({
              message: response.status,
              description: response.message
            });
            vm.$router.push({ name: "dashboard" });
          }
        });
    }, 500)
  }
};
</script>
